import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductItem from "../components/products/product-item"
import { graphql } from "gatsby"
import { colours } from "../style/colours"
import { connect } from "react-redux"
import {
  updateCartCount,
  toggleShowCart,
  addToCart,
  updateProductCategory,
} from "../state/app"
import Fuse from "fuse.js"
import MediaQuery from "react-responsive"
import Select from "react-select"
import CategoryMenu from "../components/category-menu"
import { convertShopifyId } from "../utils/utils"

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`
const Title = styled.h1`
  text-align: center;
  color: ${colours.maroonDark};
  font-size: 24px;
`
const SelectBox = styled.div`
  width: 100%;
`
const Categories = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0 50px 0;

  flex-wrap: nowrap;

  overflow-x: scroll;
`
const CategoryItem = styled.button`
  padding: 10px 15px;
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 15px;
  margin: 0 15px;
  background: ${props => (props.selected ? colours.maroonDark : "none")};
  border: 1px solid ${colours.maroonDark};
  color: ${props => (props.selected ? "white" : colours.maroonDark)};
  cursor: pointer;
  transition: 0.2s ease;

  :focus {
    outline: none;
  }

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }
`
const SearchContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
  margin-bottom: 50px;
`

const SearchBar = styled.input`
  /* height: 30px; */
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  font-family: "Muli";
  border: 1px solid #ccc;
`
const ProductsContainer = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  flex: 1;

  @media (max-width: 980px) {
    grid-template-columns: 33% 33% 33%;

  }

  @media (max-width: 750px) {
    grid-template-columns: 50% 50%;
  }
`
const Split = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const fuseOptions = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["title"],
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "56px",
    borderRadius: "1px",
    border: "1px solid #E7E7E7",
    outline: "none",
  }),
  menuList: (provided, state) => ({
    ...provided,
    fontFamily: "Muli",
    fontWeight: 500,
    color: "black",
    padding: 7,
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontFamily: "Muli",
    fontWeight: "bold",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: "Muli",
    fontWeight: "500",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontFamily: "Muli",
  }),
}
class ShopPage extends Component {
  state = {
    isSearching: false,
    searchVal: "",
    isLoading: false,
    searchableProducts: [],
    products: this.props.data.products.edges.map(product => product.node),
    results: [],
    filtered: [],
    categories: [],
  }

  componentDidMount() {
    this.setupSearchable()
    this.setupCategories()
  }

  setupSearchable = () => {
    const { products } = this.props.data
    let searchableProducts = products.edges.map(product => product.node)
    this.setState({ searchableProducts })
  }

  handleAddToCart = product => {
    const { dispatch, shoppingCart } = this.props

    let productsToReturn = shoppingCart

    //Check if the product is already in the cart
    const obj = shoppingCart.find(el => el.shopifyId === product.shopifyId)

    if (obj) {
      //find the index and adjust the count
      let index = shoppingCart.indexOf(obj)
      productsToReturn[index].count = productsToReturn[index].count + 1

      //Dispatch new cart with updated counts to state
      dispatch(updateCartCount(productsToReturn))
      dispatch(toggleShowCart(true))
    } else {
      const productWithFields = {
        ...product,
        count: 1,
        variantId: convertShopifyId(product.variants[0].shopifyId)
        
      }
      dispatch(addToCart(productWithFields))
      dispatch(toggleShowCart(true))
    }

    //Remove the cart after 2 seconds
    setTimeout(() => {
      dispatch(toggleShowCart(false))
    }, 2000)
  }

  handleSearch = e => {
    const { searchableProducts } = this.state
    this.setState({ [e.target.name]: e.target.value, isSearching: true })
    let val = e.target.value

    //Stop searching
    if (val === "") {
      this.setState({ isSearching: false })
      return
    }

    var fuse = new Fuse(searchableProducts, fuseOptions)
    let results = fuse.search(val)

    this.setState({ results })
  }

  setupCategories = () => {
    const { collections } = this.props.data

    const categories = collections.edges.map(item => ({
      value: item.node.title,
      label: item.node.title,
    }))

    this.setState({ categories }, () => {
      const { selectedProductCategory } = this.props
      if (selectedProductCategory !== "All") {
        this.handleSelectCategory(selectedProductCategory)
      }
    })
  }

  handleCategoryReset = () => {
    this.setState(
      { products: this.props.data.products.edges.map(product => product.node) },
      () => {
        this.props.dispatch(updateProductCategory("All"))
      }
    )
  }

  handleSelectCategory = category => {
    const { collections } = this.props.data

    //Get the selected Collection
    let selectedCollection = collections.edges.find(
      collection => collection.node.title === category
    )

    const categoryProducts = selectedCollection.node.products

    //Set the state with the chosen products and stop loading
    this.setState({
      products: categoryProducts,
    })

    //Update selected Category
    this.props.dispatch(updateProductCategory(category))
  }

  render() {
    const { collections } = this.props.data
    const { selectedProductCategory } = this.props
    console.log(selectedProductCategory)
    const { searchVal, isSearching, results, categories, products } = this.state
    return (
      <Layout>
        <SEO title="Shop Online" />
        <Container>
          <Title>Trusted brands & quality products just for you!</Title>
          <MediaQuery maxDeviceWidth={650}>
            <SelectBox>
              <Select
                isSearchable={false}
                styles={customStyles}
                options={categories}
                placeholder="Browse by Category"
                onChange={val => this.handleSelectCategory(val.label)}
              />
            </SelectBox>
          </MediaQuery>
          
          <hr />
          <SearchContainer>
            <SearchBar
              type="text"
              placeholder="Search our entire store..."
              value={searchVal}
              name="searchVal"
              onChange={this.handleSearch}
            />
          </SearchContainer>
          <Split>
            <CategoryMenu collections={collections} handleCategoryReset={this.handleCategoryReset} handleSelectCategory={this.handleSelectCategory} selectedCategory={selectedProductCategory} />
            {isSearching ? (
              <ProductsContainer>
                {results.map((product, index) => (
                  <ProductItem
                    key={index}
                    product={product}
                    addToCart={this.handleAddToCart}
                  />
                ))}
              </ProductsContainer>
            ) : (
              <ProductsContainer>
                {products.map((product, index) => (
                  <ProductItem
                    key={index}
                    product={product}
                    addToCart={this.handleAddToCart}
                  />
                ))}
              </ProductsContainer>
            )}
          </Split>
        </Container>
      </Layout>
    )
  }
}

export default connect(
  state => ({
    selectedProductCategory: state.app.selectedProductCategory,
    shoppingCart: state.app.shoppingCart,
  }),
  null
)(ShopPage)

export const ProductsQuery = graphql`
  query ProductQuery {
    products: allShopifyProduct(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          title
          shopifyId
          handle
          availableForSale
          description
          variants {
            compareAtPrice
            shopifyId
            title
            price
            availableForSale
            image {
                originalSrc
            }
          }
          priceRange {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          images {
            originalSrc
          }
        }
      }
    }
    collections: allShopifyCollection {
      edges {
        node {
          title
          products {
            title
            shopifyId
            availableForSale
            handle
            description
            variants {
              compareAtPrice
              shopifyId
              title
              price
              availableForSale
              image {
                  originalSrc
              }
            }
            priceRange {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            images {
              originalSrc
            }
          }
        }
      }
    }
  }
`
