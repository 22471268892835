import React from 'react'
import styled from 'styled-components';
import { colours } from '../style/colours';

const Container = styled.div`
    @media (max-width: 650px) {
        display: none;
    }
`;
const Menu = styled.ul`
    margin: 0;
    list-style: none;
    position: sticky;
    top: 120px;
`;
const CategoryItem = styled.li`
    font-family: "Muli";
    color: ${props => (props.selected ? colours.maroonDark :  "black")};
    cursor: pointer;
    transition: 0.2s ease;
    white-space: nowrap;
`;

const CategoryMenu = ({ collections, selectedCategory, handleSelectCategory, handleCategoryReset }) => {
    return (
        <Container>
            <Menu>
                <CategoryItem
                    onClick={() => handleCategoryReset()}
                    selected={selectedCategory === "All"}
                >
                    All
                </CategoryItem>
                {collections.edges.filter(el => el.node.title !== "Home page").map((collection, index) => (
                    <CategoryItem
                        selected={collection.node.title === selectedCategory}
                        key={index}
                        onClick={() =>
                            handleSelectCategory(collection.node.title)
                        }
                    >
                        {collection.node.title}
                    </CategoryItem>
                ))}
            </Menu>
        </Container>
    )
}

export default CategoryMenu